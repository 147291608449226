/* eslint-disable react-hooks/exhaustive-deps */
// Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from './ApiService';
import toast, { Toaster } from 'react-hot-toast';

const Logout = () => {
  const navigate = useNavigate();

  const errorLogout = () => toast('Unable to logout.', {
    style: {
      backgroundColor: '#e23650',
      color: '#ffffff',
    },
  });

  const logout = async () => {
  try {
    const response = await ApiService.post('/auth/jwt/logout', '');
    console.log('Response:', response);
    if(response) {   localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Redirect to login page
    navigate('/login');
  } else {
    errorLogout();
  }
  } catch(error)
  {
    errorLogout();
  }
}
  useEffect(() => {
    // Clear locally stored tokens
    logout();
    // Redirect to login page
    // navigate('/login');
  // });

  }, []);

  return <div className="font-light w-full h-screen bg-gray-100 text-gray-600 flex text-center justify-center align-center mt-4">         <Toaster/>Logging out...</div>;
};

export default Logout;
