import React, { useEffect, useState } from 'react';

const ToggleSwitch = ({ onTabChange, selectedToggleName = 'Conversation'}) => {
  const [selectedTab, setSelectedTab] = useState(selectedToggleName);

  const onSelectTab = (tab) => {
    setSelectedTab(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };
  useEffect(() => {
  
    setSelectedTab(selectedToggleName)
}, [selectedToggleName]);

  return (
    <div className="toggle-switch-container">
    <div
      className={`toggle-switch-button ${selectedTab === 'Conversation' ? 'active' : ''}`} style={{padding: '9px',marginLeft:'1px'}}
      onClick={() => onSelectTab('Conversation')}
    >
      Conversation
    </div>
    <div
      className={`toggle-switch-button ${selectedTab === 'Search' ? 'active' : ''}`} style={{padding: '9px',marginLeft:'1px'}}
      onClick={() => onSelectTab('Search')}
    >
      Search
    </div>
  </div>
  
  );
};

export default ToggleSwitch;

