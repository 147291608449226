import React, { useState, useEffect } from 'react';
import ApiService from './ApiService';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import PageLoader from './components/PageLoader';
import NavBar from './components/NavBar';
import toast, { Toaster } from 'react-hot-toast';
import { default as DefaultImage } from "./assets/defaultImage.png";

const Projects = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [searchEntry, setSearchEntry] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleToClose = () => {
    setOpen(false);
    const userEmail = localStorage.getItem('user_email');
    if (userEmail) {
      const stripePortalUrl = `https://billing.stripe.com/p/login/9AQ16udXeaPr2jKbII?prefilled_email=${encodeURIComponent(userEmail)}`;
      window.location.href = stripePortalUrl;
    } else {
      console.error('User email not found.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.get('/getprojects');
        setDashboardData(response.data);
        if (response.data.projects && response.data.projects.length === 1) {
          const singleProjectId = response.data.projects[0].project_id; 
          navigate(`/conversations/${singleProjectId}`);
        }
    
        checkLicenseExpiry(response.data);
      } catch (error) {
        handleFetchError(error);
      }
    };

    const checkLicenseExpiry = (dashboardData) => {
      dashboardData.projects.map((el, i) => {
        if (el.expire_date != null && el.expire_date < new Date()) {
          setOpen(true)
        }
      }
      )
    }

    const handleFetchError = async (error) => {
      toast.error('Unable to process');
      if (error.response && error.response.status === 401) {
        try {
          await ApiService.refresh_tokens();
          const refreshedResponse = await ApiService.get('/getprojects');   
          setDashboardData(refreshedResponse.data);
          if (refreshedResponse.data.projects && refreshedResponse.data.projects.length === 1) {
            const singleProjectId = refreshedResponse.data.projects[0].id; 
            navigate(`/projects/${singleProjectId}`);
          }
          checkLicenseExpiry(refreshedResponse.data);
        } catch (refreshError) {
          toast.error('Unable to process');
          localStorage.setItem('loggedOutFromSystem', 'true');
          navigate('/login');
        }
      } else {
        toast.error('Unable to process');
        console.log({
          data: {
            message: 'string',
            status: 'success',
            projects: [],
          },
        });
      }
    };

    fetchData();
  }, [navigate]);

  if (!dashboardData) {
    return <PageLoader />;
  }

  const cbSearch = (n) => {
    setSearchEntry(n?.target?.value || '');
  };
  const queryParams = new URLSearchParams(location.search);
  const noConvoError = queryParams.get('noConvo');

  return (
    <>
      <Toaster />
      <NavBar />
      <div className="w-full h-full pt-6 md:pt-12 flex flex-col justify-center items-center">
        <div className="font-semibold text-2xl text-gray-700 mb-4 mt-16 md:mt-24">Projects</div>
        {dashboardData?.projects.length > 0 && (
          <div className="bg-white w-full border-2 mb-8 shadow p-4 relative rounded-lg flex items-center" style={{ maxWidth: '80%' }}>
            <span className="w-auto flex justify-end items-center text-gray-500 p-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </span>
            <input
              name="projectSearch"
              onChange={cbSearch}
              id="projectSearch"
              className="border-gray-300 outline-none border-0 w-full rounded-lg p-2"
              type="text"
              placeholder="Search Projects.."
            />
          </div>
        )}
             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-center" style={{ width: '80%' }}>
  {dashboardData?.projects.length > 0 &&
    dashboardData.projects.map((el, i) => {
      if (el.project_name?.toLocaleLowerCase()?.indexOf(searchEntry?.toLocaleLowerCase()) > -1) {
        return (
          <div
            key={`el_${i}`}
            className="bg-white border border-gray-200 shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <Link to={`/conversations/${el.project_id}`} className="block h-full flex flex-col justify-between" state={el.additional_data}>
              <div className="flex flex-col items-center p-4">
                <img 
                  className="w-14 h-14 mb-2" 
                  src={el.image ? el.image : DefaultImage} 
                  alt={`${el.project_name} project`}
                  onError={(e) => { e.target.onerror = null; e.target.src = DefaultImage; }}
                />
                <h3 className="font-semibold text-lg text-gray-800 hover:text-gray-900 transition-colors text-center">
                  {el.project_name}
                </h3>
              </div>
              <div className="p-4">
                <p className="text-sm text-gray-600 mb-2">{el.project_description}</p>
              </div>
              <div className="mt-auto p-4 flex flex-col items-center">
                <p className="text-xs text-gray-500 text-center">
                </p>
              </div>
            </Link>
          </div>
        );
      }
      return null;
    })}
</div>
        {(dashboardData?.projects.length === 0 || !dashboardData?.projects.length || !dashboardData.projects) && (
          <div className="w-full my-4 text-center flex justify-center font-light">No projects found.</div>
        )}
      </div>
      {open && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center" aria-modal="true">
          <div className="bg-white rounded-lg p-8 shadow-lg">
            <h2 className="text-xl font-semibold mb-4">License Expired</h2>
            <p className="mb-4">Your license has expired. Please extend your license.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleToClose}>
              Go to Stripe Portal
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Projects;

