// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Make sure the import path is correct
import Login from './Login';
import Logout from './Logout';
import Projects from './Projects';
import Conversations from './Conversations';
import ProjectInformation from './ProjectInformation';
import ChangePassword from './pages/ChangePassword';
import SearchNav from './pages/SearchNav';



const App = () => {


  useEffect(() => {
    const handleResize = () => {
      document.body.style.height = `${window.innerHeight}px`;
      document.body.style.maxHeight = `${window.innerHeight}px`;
    };

    // Set initial body height
    handleResize();

    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <Routes>
      <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/changePassword" element={<ChangePassword />} />
          <Route exact path='/' element={<ProtectedRoute/>}>
          <Route path="/" element={<SearchNav  />} />
          <Route exact path='/projects' element={<Projects/>}/>
            <Route path="/conversations/:id" element={<Conversations/>} />
            <Route path="/project/:id/:title" element={<ProjectInformation/>} />
          </Route>

        {/* <ProtectedRoute path="/dashboard" element={<Dashboard />} /> */}
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
};

export default App;