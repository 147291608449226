/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState } from "react";

import Button from "../components/Button";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [loadingState, setLoadingState] = useState(false);

  const wrongPassword = () => toast('Incorrect Old Password.', {
    style: {
      backgroundColor: '#e23650',
      color: '#ffffff',
    },
  });
  const errorlogin = () => toast('Error while logging-in.', {
    style: {
      backgroundColor: '#e23650',
      color: '#ffffff',
    },
  });

  

  return (
    <form 
      onSubmit={(event) => {
        event.preventDefault()
       
      }}
    > 
    <Toaster/>

       <div className="container mx-auto p-8 h-screen flex flex-col items-center justify-center ">
        <div className="max-w-md w-full h-screen mx-auto flex flex-col items-center justify-center">
            <h1 className="text-4xl text-center mb-12 font-thin">PixoWeb</h1>

            <div className="bg-white w-full rounded-lg overflow-hidden p-6 shadow-2xl">
                <div className="p-8">
                        <div className="mb-5">
                            <label htmlFor="oldPassword" className="block mb-2 text-sm font-medium text-gray-600">Old Password</label>

                            <input disabled={loadingState} type="password" name="oldPassword" id="oldPassword" className="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none"/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="newPassword" className="block mb-2 text-sm font-medium text-gray-600">New Password</label>

                            <input disabled={loadingState} type="password" name="newPassword" id="newPassword" className="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none"/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-600">Confirm Password</label>

                            <input disabled={loadingState} type="password" name="confirmPassword" id="confirmPassword" className="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none"/>
                        </div>
                
                      
                        <Button stateButton={!loadingState ? 'default': 'loading'} labelButton="ChangePassword" labelLoading="change-password" customStyle="mt-10 p-3 "/>
          
                </div>
                
                <div className="flex hidden justify-between p-8 text-sm border-t border-gray-300 bg-gray-100">

                    <a href="/login" className="text-gray-600">Back to Login</a>
                </div>
            </div>
            <div className="text-gray-600 text-xs mt-4"><input disabled={loadingState}    type="checkbox"/> Stay logged-in for 30 days?</div>
        </div>
    </div>
    </form>
  );
}
export default ChangePassword;