/* eslint-disable jsx-a11y/anchor-has-content */
// Dashboard.js
import React, { useState, useEffect } from 'react';
import ApiService from './ApiService';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from './components/PageLoader';
import toast, { Toaster } from 'react-hot-toast';

const ProjectInformation = ({ match }) => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggle, setToggle] = useState(screenWidth > 1023);
  const [cid, setCid] = useState();
  
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    if(screenWidth < 1024 && toggle)
    setToggle(false);
  };

  useEffect(() => {

    console.log('toggle: ', toggle);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const callBack = () => {
  //   //   console.log('screenWidth: ', screenWidth);
  //   //   if(screenWidth < 1024)
  //   //   setToggle(!toggle);
  //   // else if(screenWidth > 1023)
  //   //   setToggle(true);
    
  //     setToggle(!toggle);
  //     console.log('toggle:- ', toggle);
  //   }

    
  


  const [projectData, setProjectData] = useState(null);
  // const [convoId, setConvId] = useState(-1);
  const [customerData, setCustomerData] = useState();
  const [filesData, setFilesData] = useState();
  // const [formData, setFormData] = useState({
  //   from_date: '',
  //   to_date: '',
  //   skip: '0',
  //   limit: '50',
  //   id: '',
  //   project_id: '7',
  //   customerid: '',
  // });
  const navigate = useNavigate(); 
  let { id, title } = useParams();
  console.log('match: ', match);
  
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async (cd) => {
    
    try {
      const response = await ApiService.get(`/balance?customerid=${cd || customerData?.id || cid}&project_id=${id}`); //${convoId > -1 ? `&id=${convoId}`:''}
      setIsLoading(false);

      console.log('response: ', response);
      // if(response?.data)
      //   localStorage.setItem('convo_data', response.data);

      setProjectData(response.data);
    } catch (error) {
      toast.error('Unable to process');
      if (error.response && error.response.status === 401) {
        // Access token expired, try refreshing tokens
        try {
          await ApiService.refresh_tokens();
          // Retry fetching dashboard data
          const refreshedResponse = await ApiService.get(`/balance?customerid=${cd || customerData?.id || cid}&project_id=${id}`);
          setIsLoading(false);

          // if(refreshedResponse?.data)
          //   localStorage.setItem('convo_data', refreshedResponse.data);

          setProjectData(refreshedResponse.data);
        } catch (refreshError) {
          toast.error('Unable to process');
          localStorage.setItem('loggedOutFromSystem', 'true');
          setIsLoading(false);
          // Refresh token also expired, redirect to homepage
          navigate('/login');
        }
      } else {
        // Other API error, handle accordingly
      }
    }
  };
  
  const fetchData2 = async () => {
    let rd = '';
    if(true) {
      let isSuccessful = false;
    try {
      const response = await ApiService.get('/users/me');
      rd = response?.data?.id;
      console.log('customerData response: ', response);
      localStorage.setItem('customerData', JSON.stringify(response.data));
      setCustomerData(response.data);
      console.log('customerData: ', customerData);
      console.log('rd: ', rd);
      setCid(rd);
      isSuccessful = true;
    } catch (error) {
      toast.error('Unable to process');
      if (error.response && error.response.status === 401) {
        // Access token expired, try refreshing tokens
        try {
          await ApiService.refresh_tokens();
          // Retry fetching dashboard data
          const refreshedResponse = await ApiService.get('/users/me');
          rd = refreshedResponse?.data?.id;
          localStorage.setItem('customerData', JSON.stringify(refreshedResponse.data));
          setCustomerData(refreshedResponse.data);
          isSuccessful = true;
        } catch (refreshError) {
          toast.error('Unable to process');
        }
      } else {
        // Other API error, handle accordingly
      }
    } finally {
      console.log('isSuccessful: ', isSuccessful);
      if(isSuccessful) fetchData(rd);
    }
  }
  };

  const fetchData3 = async (cd) => {
    
    try {
      const response = await ApiService.get(`/getfiles?&project_id=${id}`); //${convoId > -1 ? `&id=${convoId}`:''}

      setFilesData(response.data);
      console.log('response: ', response);      setIsLoading(false);

      // if(response?.data)
      //   localStorage.setItem('convo_data', response.data);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Access token expired, try refreshing tokens
        try {
          await ApiService.refresh_tokens();
          // Retry fetching dashboard data
          const refreshedResponse = await ApiService.get(`/getfiles?&project_id=${id}`);
         
          setFilesData(refreshedResponse.data);
           setIsLoading(false);

          // if(refreshedResponse?.data)
          //   localStorage.setItem('convo_data', refreshedResponse.data);

        } catch (refreshError) {
          
          toast.error('Unable to process');
          localStorage.setItem('loggedOutFromSystem', 'true');
          setIsLoading(false);
          // Refresh token also expired, redirect to homepage
          navigate('/login');
        }
      } else {
        // Other API error, handle accordingly
      }
    }
  };

  useEffect(() => {
    const cD = JSON.parse(localStorage.getItem('customerData'));
    console.log('localStorage.getItem: ', cD);
    setCustomerData(cD);
    console.log('customerData: ', customerData);
    setIsLoading(true);
    if(!customerData?.id)
    {
      fetchData2();
    } else {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData3();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData])

  if (!projectData || isLoading) {
    return <PageLoader/>;
  }

  console.log('customerData: ', customerData);
  console.log('projectData: ', projectData);

  return (
    <>
    <Toaster/>
    <div className="p-3 md:p-6 flex flex-row w-full mx-auto justify-end">
    <a href='/projects' className="hover:underline mb-12 text-sm font-semibold hover:font-bold">	&crarr; Projects</a>
    </div>
    <div className="flex flex-row w-full mx-auto justify-center">
    <div className="p-3 md:p-6 flex flex-col bg-white w-full mx-2 md:mx-4 my-4 border mx-auto shadow-md rounded-xl font-light transition-all flex justify-between items-center" style={{ maxWidth: '640px' }}>
    {title &&
    <div className="flex w-full flex-col md:flex-row justify-center items-center p-2 bg-gray-100 rounded-xl">
      <div className="w-full md:w-1/2 font-semibold">Project</div>
      <div className="w-full md:w-1/2 line-clamp-3" style={{color:"black"}}>{title}</div>
    </div>}
    <div className="flex w-full flex-col md:flex-row justify-center items-center p-2">
      <div className="w-full md:w-1/2 font-semibold">Project ID</div>
      <div className="w-full md:w-1/2">{projectData.project_id}</div>
    </div>
    <div className="flex w-full flex-col md:flex-row justify-center items-center p-2 bg-gray-100 rounded-xl">
      <div className="w-full md:w-1/2 font-semibold">Balance Limit</div>
      <div className="w-full md:w-1/2">{process.env.REACT_APP_CURRENCY_SYMBOL}{projectData.balancelimit}</div>
    </div>
    <div className="flex w-full flex-col md:flex-row justify-center items-center p-2 ">
      <div className="w-full md:w-1/2 font-semibold">Cost</div>
      <div className="w-full md:w-1/2">{process.env.REACT_APP_CURRENCY_SYMBOL}{projectData.cost}</div>
    </div>
    </div>
    </div>
  
    <div className="flex flex-row w-full mx-auto justify-center">
    <div className="p-3 md:p-6 bg-white w-full flex flex-col mx-2 md:mx-4 my-4 border mx-auto shadow-md rounded-xl font-light transition-all flex justify-between items-center" style={{ maxWidth: '640px' }}>
    <h3 className="font-semibold">Files</h3>
    <div className="w-full h-4 border-b-2 border-gray-100"/>
  
      {!filesData && <div className="flex flex-row w-full justify-between"><div><span className="skeleton-box h-5 w-4/5 inline-block"></span></div><div><span className="skeleton-box h-5 w-1/5 inline-block"></span></div></div>}
      {filesData?.filenames?.length === 1 && <div className="m-3 md:m-6 justify-center flex text-center">No files found.</div>}
      <div className="flex w-full flex-col md:flex-row justify-between items-center p-2 ">
        <div className="w-full md:w-1/2 line-clamp-3 justify-center md:justify-start flex">CSV Data Sheet 1</div>
        <div className="w-full md:w-1/2 md:text-end justify-center md:justify-end flex"><a href="https://filesamples.com/samples/document/csv/sample4.csv" alt="Download" ><i className="fa fa-download text-gray-300 hover:text-gray-600" aria-hidden="true"></i></a></div>
      </div>
      <div className="flex w-full flex-col md:flex-row justify-between items-center p-2 bg-gray-100 rounded-xl">
        <div className="w-full md:w-1/2 line-clamp-3 justify-center md:justify-start flex">Project Video 1</div>
        <div className="w-full md:w-1/2 md:text-end justify-center md:justify-end flex"><a href="https://sample-videos.com/video321/mp4/480/big_buck_bunny_480p_10mb.mp4" alt="Download" ><i className="fa fa-download text-gray-300 hover:text-gray-600" aria-hidden="true"></i></a></div>
      </div>
      <div className="flex w-full flex-col md:flex-row justify-between items-center p-2">
        <div className="w-full md:w-1/2 line-clamp-3 justify-center md:justify-start flex">Picture 1</div>
        <div className="w-full md:w-1/2 md:text-end justify-center md:justify-end flex"><a href="https://filesamples.com/samples/image/jpg/sample_1280%C3%97853.jpg" alt="Download" ><i className="fa fa-download text-gray-300 hover:text-gray-600" aria-hidden="true"></i></a></div>
      </div>
    </div>
    </div>

    </>
  );
};

export default ProjectInformation;
